<template>
  <app-page>
    <app-container>
      <app-section title="Desktop" v-if="desktopImages && desktopImages.length">
        <div class="grid grid--gutter-30">
          <div class="col sc-768-4" v-for="({ url }, i) of desktopImages" :key="i">
            <app-card
              :img-src="url"
              :img-ratio="240 / 490 * 100"
              @click.native="openCarousel(desktopImages, i)"
            />
          </div>
        </div>
      </app-section>

      <app-section title="Mobile" v-if="mobileImages && mobileImages.length">
        <div class="grid grid--gutter-30">
          <div class="col sc-580-3 sc-992-2" v-for="({ url }, i) of mobileImages" :key="i">
            <app-card
              :img-src="url" :img-ratio="362 / 203 * 100"
              @click.native="openCarousel(mobileImages, i)"
            />
          </div>
        </div>
      </app-section>

      <app-section title="Контакти">
        <section-contacts />
      </app-section>
    </app-container>

    <app-gallery v-model="gallery" :images="galleryImages" :init-slide="galleryInitSlide" />
  </app-page>
</template>

<script>
import AppContainer from '../components/AppContainer.vue';
import AppCard from '../components/AppCard.vue';
import AppSection from '../components/AppSection.vue';
import AppPage from '../components/AppPage.vue';
import AppGallery from '../components/AppGallery.vue';
import SectionContacts from '../components/SectionContacts.vue';

export default {
  name: 'Work',

  components: {
    SectionContacts,
    AppGallery,
    AppPage,
    AppSection,
    AppCard,
    AppContainer,
  },

  data: () => ({
    gallery: false,
    galleryImages: [],
    galleryInitSlide: 0,
    work: {},
  }),

  computed: {
    desktopImages() {
      return this.work?.work_images
        ?.filter(({ work_image_type: type }) => type === 'desktop')
        ?.map(({ work_image: image }) => image);
    },

    mobileImages() {
      return this.work?.work_images
        ?.filter(({ work_image_type: type }) => type === 'mobile')
        ?.map(({ work_image: image }) => image);
    },
  },

  methods: {
    openCarousel(images, initSlide) {
      this.gallery = true;
      this.galleryImages = images.map(({ url }) => url);
      this.galleryInitSlide = initSlide;
    },

    async fetchWork() {
      try {
        const { data } = await this.$prismic.client.getByUID('work', this.$route.params.id);
        this.work = data;
      } catch (e) {
        console.error(e);
      }
    },
  },

  created() {
    this.fetchWork();
  },
};
</script>
